@import '../../utils/variables';

$fixed-menu-height: 80px;
$mobile-menu-width: 300px;

.navbar {
    height: $fixed-menu-height;
}

#navbar-logo {
    img {
        max-width: calc(#{$mobile-menu-width} - 2rem);
    }
}

// sm, md, lg, xl
@media only screen and (min-width: 576px) {
    // ...
}

// md, lg, xl
@media only screen and (min-width: 768px) {
    // ...
}

// lg, xl
@media only screen and (min-width: 992px) {
    // ...
}

// xl
@media only screen and (min-width: 1200px) {
    .navbar {
        height: auto;
    }
    #navbar-logo img {
        max-width: 150px;
    }
    #navbar-overlay {
        opacity: 0;
    }
    .sticky-xl-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
    #navbar-menu {
        .nav-link {
            &:last-of-type {
				padding-left: 2.4rem;
                padding-right: 2.4rem;
            }
        }
    }
}

// xs, sm, md, lg
@media only screen and (max-width: 1199.98px) {
    .navbar {
        background: transparent!important;
        width: 0;
        left: 15px;
        position: fixed;
        top: 0;
        z-index: $zindex-fixed;
    }
    #menu {
        position: fixed;
        top: 0;
        left: 0;
        padding-top: 0!important;
        height: 100vh!important;
        width: auto;
        width: $mobile-menu-width;
        background: $primary;
        overflow-x: auto;

        @media only screen and (max-width: 575.98px){
            width: 220px;
        }

        & > * {
            min-width: 100%;
        }
    }
    .transition-standard{
        transition: all 0.3s ease-in-out;
    }
    .navbar-toggler {
        outline: none!important;
        z-index: 1;
        background: #3DBDEE;
        padding: 0;
        height: 40px;
        width: 40px;
        box-sizing: content-box;
        line-height: 0;
        border: 2px solid #fff;
        @media only screen and (max-width: 1199.98px){
            top: 15%;
        }
        
        &:hover {
            opacity: 0.8;
        }
    }
    .navbar-toggler-icon {
        background-image: none!important;
        background: transparent;
        height: 2px;
        left:50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1.5rem;
        &::before {
            transform: rotate(45deg);
            top: 0;
        }

        &::after {
            transform: rotate(-45deg);
            bottom: 0;
        }
        &::after,
        &::before {
            background-color: white;
            content: "";
            display: block;
            height: 2px;
            position: absolute;
            width: 1.5rem;
            transform-origin: center;
        }
    }

        .dropdown-menu .nav-item .dropdown-item{
            background-color: #3DBDEE;
        }
        .dropdown-menu .nav-item .dropdown-item img{
            opacity: 0.9;
        }
    
    .navbar-toggler.collapsed .navbar-toggler-icon {
        &::before {
            transform: none;
            top: -7px;
        }
        &::after {
            transform: none;
            bottom: -7px;
        }
        background: white;
    }
    .navbar-toggler.collapsed {
        & ~ #menu {
            transform: translateX(-#{$mobile-menu-width});
        }
        & ~ #navbar-overlay {
            opacity: 0;
            visibility: hidden;
        }
    }
    #menu.collapse {
        &:not(.show) {
          display: inline-grid;//block !important;
        }
    }
    .collapse {
        display: block;
    }
    #navbar-overlay {
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        transition: all 0.3s ease-in-out;
        opacity: 1;
        visibility: visible;
        z-index: -1;
    }
    #navbar-menu {
        margin-left: -1rem;
        margin-right: -1rem;
        width: calc(100% + 2rem);
        position: relative;
        .nav-item {
            transition: all 0.3s ease-in-out;
            .nav-link,
            .dropdown-item {
                padding: 4rem;
                border-top: 1px solid $secondary;
                border-bottom: 1px solid $secondary;
                width: 100%;
                margin-top: -1px;
                color:#fff;
                @media only screen and (max-width: 1199.98px){
                    border-top: none;
                    border-bottom: none;
                    padding: 2rem;
                    text-transform: uppercase;
                }
                @media only screen and (max-width: 575.98px){
                    padding: 1.2rem;
                }
            }
            & + .nav-item .nav-link {
                border-top: 1px solid transparent;
                color:#fff;
                @media only screen and (max-width: 1199.98px){
                  text-decoration: none;
                }

            }
            &.divider {
                display: none;
            }
            &.active {
               // background: $secondary;
               background:#3DBDEE;
            }
            &.active.parent {
               
            }
            &:hover,
            &:focus {
            }
            &.dropdown.show {
                transition: none;
                position: absolute;
                z-index: 1;
                top: 0;
                width: 100%;
                height: 100%;
                background: $primary;
                .nav-link {
                    border-top: 1px solid $primary;
                    border-bottom: 2px solid;
                    margin-top: -1px;
                }
               
                .nav-item {
                    a {
                        padding-left: 2rem;
                        @media only screen and (max-width: 1000px){
                            text-decoration: none;
                          }
                    }
                }
                .dropdown-toggle::after {
                    transform: rotate(90deg);
                }
                .dropdown-menu {
                    height: auto;
                    background: $primary;
                    @media only screen and (max-width: 991.98px){
                        width: 110%;
                        height: 70vh;
                    }
                }
            }
          
            .dropdown-toggle::after {
                float: right;
                margin-top: calc((1.5em - 0.3em)/2);
                transform: rotate(-90deg);
                @media only screen and (max-width: 1199.98px){
                    margin-top: -2rem;
                    margin-right: -2rem;
                }
               
            }
        }
        
        
        .dropdown-menu {
            padding: 0;
            margin: 0;
            border: none;
            background: transparent;
            margin-top: -1px;
            height: 0;
            transition: all 0.3s ease-in-out;
            .dropdown-item {
                color:#fff;
                font-size: 1.5rem;
                white-space: normal;
                @media only screen and (max-width: 1199.98px){
                    font-size: 1.2rem;
                }
                &.active,
                &:hover,
                &:focus {
                    color: white;
                    background: transparent;
                }
            }
            .nav-item.active .dropdown-item {
                    color: white;
            }
        }
    }
}
